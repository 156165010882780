#map {
	height: 300px;
	width: auto;
	//	width: 80vw;
}

.s0 {
	fill-rule: evenodd;
	clip-rule: evenodd;
}

.s1 {
	font-size: 30px;
	line-height: 1.25;
	font-family: "RobotoMono Nerd Font Mono";
	-inkscape-font-specification: "RobotoMono Nerd Font Mono";
	letter-spacing: 0px;
	word-spacing: 0px;
	fill: #ececec;
}

.map-loader {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10000;
	height: 300px;
	/* background: $secondary; */
	opacity: 0.7;
	width: 100%;
	text-align: center;

	div {
		margin: 0;
		position: absolute;
		top: 47%;
	}
}

.map-container {
	position: relative;
}

.show-item {
	font-weight: bold;
}

.map-fake {
	background: red;
	width: 100%;
	height: 100%;
}

.tree-node-item {
	margin-bottom: 10px;
}

#show-device-map {
	height: 300px;
	// width: 80vw;

}

.device-message-text {

	//	width: 400px;
	span {
		//		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		//		white-space: nowrap;
	}

}


// Override ReactJsonViewer

.x-json-string {
	color: #2e64e2 !important;
}